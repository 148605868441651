import { useAuthSignInWithPopup } from "@react-query-firebase/auth";
import { auth, googleAuthProvider } from "../../data/firebase";
import { DidomiCard, DidomiButton } from "@didomi/ui-atoms-react";

import Logo from "../../assets/logo.png";

const Login = () => {
  const { mutate: signInWithPopup } = useAuthSignInWithPopup(auth);

  return (
    <section className="bg-pink-gradient h-screen flex flex-col justify-center items-center">
      <DidomiCard>
        <div className="flex flex-col items-center w-full h-full">
          <img
            className="mb-xs"
            src={Logo}
            alt="Dinnovation, Didomi's hackathon"
          />

          <div className="font-sans text-body-normal text-primary-blue-6 mb-s text-center">
            <div>
              Welcome to <strong>Dinnovation 2023</strong>
            </div>
            <div>Didomi's first internal Hackathon</div>
          </div>
          <div className="font-sans text-body-normal text-primary-blue-6 mb-l text-center">
            Use your Didomi Google account to login
          </div>
          <DidomiButton
            variant="secondary"
            iconLeft="google"
            onClick={async () => {
              try {
                await signInWithPopup({
                  provider: googleAuthProvider,
                });
              } catch (error) {}
            }}
          >
            <div className="pl-xxxs">Sign in</div>
          </DidomiButton>
        </div>
      </DidomiCard>
    </section>
  );
};

export { Login };
