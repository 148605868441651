import React, { useState } from "react";
import {
  useGetIdeaById,
  useGetUser,
  useGetUserDataById,
  useUpdateIdea,
  useDeleteIdea,
  useNotifyJoinIdea,
  useNotifyLeaveIdea,
} from "../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import {
  DidomiSecondaryHeader,
  DidomiEmptyState,
  DidomiLoader,
  DidomiIllustration,
  DidomiChipList,
  DidomiChip,
  DidomiButton,
  DidomiBackButtonLink,
} from "@didomi/ui-atoms-react";
import {
  ConfirmJoinModal,
  ConfirmLeaveModal,
  LoadingModal,
  EditIdeaModal,
  ConfirmDeleteIdeaModal,
  CannotDeleteIdeaModal,
} from "../../modals";
import { SubscribersList } from "../../components";
import { useQueryClient } from "react-query";

const IdeaDetails = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [displayConfirmJoinModal, setDisplayConfirmJoinModal] = useState(false);
  const [displayConfirmLeaveModal, setDisplayConfirmLeaveModal] =
    useState(false);
  const [displayEditIdeaModal, setDisplayEditIdeaModal] = useState(false);
  const [displayDeleteIdeaModal, setDisplayDeleteIdeaModal] = useState(false);
  const { id } = useParams();

  const { idea, isLoading } = useGetIdeaById(id);

  const user = useGetUser();

  const { notifyJoinIdea } = useNotifyJoinIdea();

  const { notifyLeaveIdea } = useNotifyLeaveIdea();

  const { user: createdByName } = useGetUserDataById(idea?.createdBy);

  const { updateIdea, isLoading: isUpdatingIdea } = useUpdateIdea(id, {
    onSuccess: () => {
      queryClient.invalidateQueries("idea-" + id);
    },
  });

  const { deleteIdea, isLoading: deletingIdea } = useDeleteIdea(id, {
    onSuccess: () => {
      queryClient.invalidateQueries("idea-" + id);
    },
  });

  const isUsersIdea = idea?.createdBy === user.uid;
  const isUserSubscribedToIdea = idea?.subscribedBy?.includes(user.uid);
  const hasSubscribers = idea?.subscribedBy?.length > 0;
  const canJoin =
    !isUserSubscribedToIdea &&
    !isUsersIdea &&
    (idea?.subscribedBy?.length < 4 ||
      !idea?.subscribedBy ||
      (idea?.createdBy === "Dl2Qf1DanvRoWfo2gEYa1J14UTR3" &&
        idea?.subscribedBy?.length < 5));
  const canLeave = isUserSubscribedToIdea && !isUsersIdea;

  if (isLoading) {
    return (
      <div className="w-screen min-h-screen flex flex-col justify-center items-center">
        <DidomiLoader />
      </div>
    );
  }

  return (
    <>
      <section className="bg-white min-h-screen flex flex-col pt-xl px-xl">
        <DidomiSecondaryHeader className="mb-xl" titleText="Hackathon idea">
          <div slot="back-button">
            <DidomiBackButtonLink
              onClick={() => navigate("/")}
              className="z-10 relative"
            />
          </div>
          <div slot="actions">
            <div className="whitespace-nowrap">
              {canJoin && (
                <DidomiButton
                  className="ml-s!"
                  disabled={idea?.example}
                  onClick={() => setDisplayConfirmJoinModal(true)}
                >
                  Join!
                </DidomiButton>
              )}
              {isUsersIdea && (
                <>
                  <DidomiButton
                    className="ml-s!"
                    variant="top"
                    onClick={() => setDisplayEditIdeaModal(true)}
                  >
                    Edit
                  </DidomiButton>
                  <DidomiButton
                    className="ml-s!"
                    variant="secondary"
                    onClick={() => setDisplayDeleteIdeaModal(true)}
                  >
                    Delete
                  </DidomiButton>
                </>
              )}
              {canLeave && (
                <DidomiButton
                  className="ml-s!"
                  variant="top"
                  onClick={() => setDisplayConfirmLeaveModal(true)}
                >
                  Leave
                </DidomiButton>
              )}
            </div>
          </div>
        </DidomiSecondaryHeader>

        <div className="grid grid-flow-col grid-cols-12 grid-rows-1 auto-cols-min gap-xs mt-m h-full">
          <DidomiIllustration
            name="create-a-preference"
            className="col-span-2 hidden xl:block"
          />
          <div className="col-span-full md:col-span-8 xl:col-span-6 flex h-full">
            <div className="flex-1 h-full">
              {isLoading ? (
                "Loading.."
              ) : idea ? (
                <>
                  <>
                    <div className="p-1 w-full">
                      <div className="flex flex-col">
                        <div className="flex items-center">
                          <h2 className="h2 text-primary-blue-6">
                            {idea?.name}
                          </h2>
                          {idea?.example && (
                            <DidomiChip
                              className="ml-s"
                              variation="basic-outline"
                              label="Example"
                              basicType="neutral"
                            />
                          )}
                          {createdByName && (
                            <DidomiChip
                              className="ml-s"
                              label={createdByName?.name}
                              variation="basic"
                              basicType="info"
                            />
                          )}
                        </div>
                        <div className="overflow-hidden pt-xs relative flex flex-col">
                          <h3 className="h3 text-primary-blue-6 mt-s mb-xs">
                            Description
                          </h3>
                          <div className="mb-s font-sans text-body-normal text-primary-blue-6 whitespace-pre-line">
                            {idea?.description}
                          </div>

                          <h3 className="h3 text-primary-blue-6 mt-s mb-xs">
                            Needs help with
                          </h3>

                          {idea?.help?.length > 0 ? (
                            <div className="pb-6 flex items-center">
                              <DidomiChipList>
                                <div>
                                  {idea?.help.map((h) => (
                                    <DidomiChip
                                      key={idea?.name + "-" + h}
                                      variation="basic-outline"
                                      label={h}
                                    />
                                  ))}
                                </div>
                              </DidomiChipList>
                            </div>
                          ) : (
                            "-"
                          )}
                        </div>

                        <h3 className="h3 text-primary-blue-6 mt-s mb-xs">
                          Who's participating?
                        </h3>
                        {idea?.subscribedBy?.length > 0 ? (
                          <div className="pb-6">
                            <div className="mr-6 flex justify-between">
                              <SubscribersList
                                name={idea?.name}
                                subscribedBy={idea?.subscribedBy}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="pb-6 font-sans text-body-normal text-primary-blue-6">
                            No body has joined yet, be the first!
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                </>
              ) : (
                <DidomiEmptyState
                  className="h-full"
                  illustrationName="no-matches-found"
                >
                  We had an issue loading the idea
                </DidomiEmptyState>
              )}
            </div>
          </div>
        </div>
      </section>
      <ConfirmJoinModal
        isOpen={displayConfirmJoinModal}
        onClose={() => setDisplayConfirmJoinModal(false)}
        onChange={(e) => setDisplayConfirmJoinModal(e.detail)}
        onSave={() => {
          try {
            updateIdea({
              subscribedBy: idea?.subscribedBy?.length
                ? idea?.subscribedBy.concat(user.uid)
                : [user.uid],
            });
            notifyJoinIdea({ idea: { name: idea.name, id: idea.uid }, user });
          } catch (_) {}
          setDisplayConfirmJoinModal(false);
        }}
      />
      <ConfirmLeaveModal
        isOpen={displayConfirmLeaveModal}
        onClose={() => setDisplayConfirmLeaveModal(false)}
        onChange={(e) => setDisplayConfirmLeaveModal(e.detail)}
        onSave={() => {
          try {
            updateIdea({
              subscribedBy: idea?.subscribedBy?.filter((id) => id !== user.uid),
            });
            notifyLeaveIdea({ idea: { name: idea.name, id: idea.uid }, user });
          } catch (_) {}

          setDisplayConfirmLeaveModal(false);
        }}
      />
      <EditIdeaModal
        idea={idea}
        isOpen={displayEditIdeaModal}
        onClose={() => setDisplayEditIdeaModal(false)}
        onChange={(e) => setDisplayEditIdeaModal(e.detail)}
        onSave={(idea) => {
          updateIdea(idea);
          setDisplayEditIdeaModal(false);
        }}
      />
      <CannotDeleteIdeaModal
        isOpen={displayDeleteIdeaModal && hasSubscribers}
        onChange={(e) => setDisplayDeleteIdeaModal(e.detail)}
        onSave={() => setDisplayDeleteIdeaModal(false)}
      />
      <ConfirmDeleteIdeaModal
        isOpen={displayDeleteIdeaModal && !hasSubscribers}
        onClose={() => setDisplayDeleteIdeaModal(false)}
        onChange={(e) => setDisplayDeleteIdeaModal(e.detail)}
        onSave={() => {
          deleteIdea();
          setDisplayDeleteIdeaModal(false);
        }}
      />
      <LoadingModal isOpen={isUpdatingIdea || deletingIdea} />
    </>
  );
};

export { IdeaDetails };
