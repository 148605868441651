import { useEffect } from "react";
import { Login, IdeaList, IdeaDetails } from "..";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { DidomiLoader } from "@didomi/ui-atoms-react";
import { useAuthUser } from "@react-query-firebase/auth";
import { useAuthMethods, useGetUserDataById } from "../../hooks";
import { auth } from "../../data/firebase";

const App = () => {
  const { createUser } = useAuthMethods();
  const { isLoading, data: user } = useAuthUser(["user"], auth);
  const { user: userData, isFetched: hasFetchedUserData } = useGetUserDataById(
    user?.uid
  );

  useEffect(() => {
    if (hasFetchedUserData && user && createUser) {
      if (!userData) {
        createUser({
          uid: user.uid,
          name: user.displayName,
          email: user.email,
        });
      }
    }
  }, [hasFetchedUserData, userData, user, createUser]);

  if (isLoading) {
    return (
      <div className="w-screen h-screen flex flex-col justify-center items-center">
        <DidomiLoader />
      </div>
    );
  }

  if (!user) {
    return <Login />;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<IdeaList />} />
        <Route path="/idea/:id" element={<IdeaDetails />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};

export { App };
